import { useModule } from '@/controllers/manager'
import { messageAlerts } from '@/middlewares/responseHandler'
import store from '@/store/index'

export default {
  namespaced: true,
  state: {
    module: 'zona',
    loading: false,
    zonas: [],
  },
  getters: {},
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    SET_ZONAS(state, payload) {
      state.zonas = payload
    },
  },
  actions: {
    async fetchZonas({ state, commit }, payload) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        const res = await controller.getAllZonas(payload)
        commit('SET_ZONAS', res.data)
        return res
      } catch (e) {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return []
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async listZonas({ state, commit }, payload) {
      commit('SET_LOADING', true)
      store.commit('app/RESET_ALERT')
      const controller = useModule(state.module)
      try {
        const res = await controller.listWithoutPagination(payload)
        return res
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return []
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async addZona({ commit, state }, zonaData) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        await controller.createZone(zonaData)
        store.commit('app/SET_ALERT', messageAlerts.a200Alert)
        return null
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return null
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async editZona({ commit, state }, payload) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        await controller.updateZona(payload)
        store.commit('app/SET_ALERT', messageAlerts.a200Alert)
        return null
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return null
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async deleteZona({ commit, state }, zonaData) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        await controller.delete(zonaData)
        store.commit('app/SET_ALERT', messageAlerts.a200Alert)
        return null
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return null
      } finally {
        commit('SET_LOADING', false)
      }
    },
  },
}
